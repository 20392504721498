import * as React from 'react';
import {CheckIcon} from '@heroicons/react/outline';
import Layout from '../../../components/layout';

const features = [
  {
    name: 'Part of Appsuite',
    description:
      'Do you really want to manage multiple tools to one aspect of Salesforce? Appsuite includes other products like Realsync for backup/store/datasync, Gooddata for data masking and Metasync (upcoming) but complete metadata solution',
  },
  {
    name: 'Digital Chain of Custody',
    description:
      'You are a medium/large enterprise and wants to sure digital chain of custody for connections/data, check. Realtask can be installed as virtual appliance mode in your AWS Account ensure 100% control and security',
  },
  {
    name: 'Best Salesforce Data Migration Product',
    description:
      'Realtask is hands down the best Salesforce data migration product, bar none. It provides many capabilities to deal with intricacies of Salesforce and how it works.',
  },
  {
    name: 'Supports Targets you care about',
    description:
      'Realtask support source/sinking data from/to systems that you care about like DB, Google Sheet, SFTP, S3 etc., Additional targets will be added if you need',
  },
  {
    name: 'Team Collaboration',
    description:
      'Share at will with none or many, you are in control. With private, all and custom sharing, you can get precise access control to achieve your goals',
  },
  {
    name: 'Multi-domain functionality',
    description:
      'Realtask can be used to Run Apex Scripts, Import Data using batch or bulk api, Export data/attachments, Copy data from one org to another all in one tool',
  },
  {
    name: 'Cost',
    description: 'Realtask provides best tool for the job, which costs less than any other product',
  },
  {
    name: 'Security',
    description:
      'What use is a product if you cannot trust it to keep your data safe? Realtask builts on top of best class security as part of Appsuite. With Cloud-Premise Virtual Appliance deployment model, you get best of both worlds.',
  },
  {
    name: 'Team that has your back',
    description:
      'We have been developing Salesforce related products for more than 12 years and have your back if you run into issues and will enhance product to support your needs',
  },
];

export default function Example() {
  return (
    <Layout>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-10 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold text-gray-900">Why Realtask?</h2>
            <p className="mt-4 text-lg text-gray-800">
              There may be a product that does a feature of Realtask so you might be wondering why Realtask? Here are some of the things that we think
              why Realtask is value add to your company
            </p>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-2xl leading-6 font-medium text-gray-800">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-lg text-gray-700">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </Layout>
  );
}
